import createVueApp from '@phoenix/createVueApp';
import { defineAsyncComponent } from 'vue';
import CurrentCountry from '@phoenix/components/CurrentCountry';
import GeoLoc from '@maison/components/GeoLoc';
import { CURRENT_COUNTRY_CHANGED, emitter } from '@phoenix/event-bus';

const currentCountry = new CurrentCountry();
new GeoLoc(currentCountry);

Object.assign(window, {
  currentCountry,
  GeoLoc,
});

const drawersLocalisations = document.querySelectorAll('.drawer-localisation');
drawersLocalisations.forEach((drawerLocalisation) => {
  drawerLocalisation.addEventListener('click', () => {
    emitter.on(CURRENT_COUNTRY_CHANGED, () => window.location.reload());
  });
});

if (document.getElementById('phoenix-wishlist-panel')) {
  const app = createVueApp({}, true);
  app.component(
    'WishlistPanel',
    defineAsyncComponent(() => import('../components/wishlist/WishlistPanel.vue'))
  );
  app.mount('#phoenix-wishlist-panel');
}

if (document.getElementById('phoenix-wishlist-drawer')) {
  const app = createVueApp({}, true);
  app.component(
    'WishlistDrawer',
    defineAsyncComponent(() => import('../components/wishlist/WishlistDrawer.vue'))
  );
  app.mount('#phoenix-wishlist-drawer');
}

if (document.getElementById('phoenix-cart-panel')) {
  const app = createVueApp({}, true);
  app.component(
    'CartPanel',
    defineAsyncComponent(() => import('../components/checkout/summary/CartPanel.vue'))
  );
  app.mount('#phoenix-cart-panel');
}

if (document.getElementById('phoenix-wishlist-icon')) {
  const app = createVueApp({}, true);
  app.component(
    'WishlistIcon',
    defineAsyncComponent(() => import('../components/checkout/summary/WishlistIcon.vue'))
  );
  app.mount('#phoenix-wishlist-icon');
}

if (document.getElementById('phoenix-cart-badge')) {
  const app = createVueApp({}, true);
  app.component(
    'Badge',
    defineAsyncComponent(() => import('../components/checkout/summary/Badge.vue'))
  );
  app.mount('#phoenix-cart-badge');
}

if (document.getElementById('phoenix-account-panel')) {
  const app = createVueApp({}, true);
  app.component(
    'AccountPanel',
    defineAsyncComponent(() => import('../components/myAccount/nav/AccountPanel.vue'))
  );
  app.mount('#phoenix-account-panel');
}
