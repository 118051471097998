import AbstractGeoLoc from '@phoenix/components/AbstractGeoLoc';
import { Collapse } from '@maison/Toolkit';

export default class GeoLoc extends AbstractGeoLoc {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldShowBanner(countryCodeFromIP): boolean {
    return false;
  }

  showPropositionBanner(): void {
    const collapse = document.querySelector(this.COUNTRY_PROPOSITION_BANNER_SELECTOR);
    if (collapse) {
      collapse.classList.add('is-expanded');
      new Collapse(collapse);
    }
  }
}
